<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulaciones Internas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Postulaciones Internas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button type="button" class="btn bg-navy" @click="init()">
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success float-right"
                        data-toggle="modal"
                        data-target="#modal-form-postulacion-export"
                        @click="$refs.PostulacionExport.resetFilter()"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  style="font-size: 11px; overflow-x: auto"
                  class="table table-bordered table-striped table-hover table-sm team-roster-table table-responsive"
                >
                  <thead>
                    <tr>
                      <th>
                        # Solicitud
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.cs_solicitud_interna_id"
                          @input="init()"
                        />
                      </th>

                      <th>
                        Tipo Servicio
                        <v-select
                          v-model="filtros.tipos_servicios"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          :options="listasForms.tipoServicios"
                          :filterable="false"
                          @input="init()"
                        ></v-select>
                      </th>
                      <th>Tipo Vehículo/Equipo</th>
                      <th>Cant.</th>
                      <th>
                        Empresa
                        <v-select
                          v-model="filtros.slct_empresa"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          :options="empresas"
                          :filterable="false"
                          @search="buscarEmpresas"
                          @input="init()"
                        ></v-select>
                      </th>
                      <th>
                        Fecha Inicial
                      </th>
                      <th>
                        Tiempo Servicio <small>(días)</small>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.tiempo_serv"
                          @change="init()"
                        />
                      </th>
                      <th>
                        Material
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.descripcion_material"
                          @input="init()"
                        />
                      </th>
                      <th>
                        Estado Solicitud
                        <select
                          id="estado"
                          class="form-control form-control-sm"
                          v-model="filtros.estados"
                          @change="init()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados_solicitudes"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="soli in postulaciones.data" :key="soli.id">
                      <td class="text-center">
                        {{ soli.cs_solicitud_interna_id }}
                      </td>

                      <td class="text-center pl-5 pr-5">
                        {{ soli.nTipoServicio }}
                      </td>
                      <td>
                        {{
                          soli.tipo_servicio == 1
                            ? soli.nTipoRemolque
                              ? soli.nTipoVh + "-" + soli.nTipoRemolque
                              : soli.nTipoVh
                            : soli.tipo_servicio == 2
                            ? soli.nTipoEqui
                            : "Sin establecer"
                        }}
                      </td>
                      <td class="text-center">{{ soli.cantidad_vh_equi }}</td>
                      <td>
                        {{ soli.empresa ? soli.empresa.razon_social : "" }}
                      </td>
                      <td class="text-center">
                        {{
                          soli.solicitud_interna
                            ? soli.solicitud_interna.fecha
                            : ""
                        }}
                      </td>
                      <td class="text-center">{{ soli.tiempo_serv }}</td>
                      <td>
                        <small>{{ soli.descripcion_material }}</small>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            soli.estado == 1
                              ? 'bg-warning'
                              : soli.estado == 2
                              ? 'bg-success'
                              : 'bg-secondary'
                          "
                        >
                          {{ soli.nEstado }}
                        </span>
                      </td>
                      <td class="text-right">
                        <div class="btn-group">
                          <button
                            type="button"
                            @click="asignarPostulaciones(soli, 1)"
                            class="btn btn-sm bg-navy"
                          >
                            <i class="fas fa-edit"></i>
                          </button>

                          <button
                            type="button"
                            v-if="
                              $store.getters.can(
                                'cs.internosPostulaciones.show'
                              )
                            "
                            @click="asignarPostulaciones(soli, 2)"
                            class="btn btn-sm bg-primary"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="postulaciones.total">
                  <p>
                    Mostrando del <b>{{ postulaciones.from }}</b> al
                    <b>{{ postulaciones.to }}</b> de un total:
                    <b>{{ postulaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="postulaciones"
                  @pagination-change-page="init"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <PostulacionExport ref="PostulacionExport" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import PostulacionExport from "./PostulacionExport";
import moment from "moment";
export default {
  name: "PostulacionIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    PostulacionExport,
  },
  data() {
    return {
      cargando: true,
      distancia: null,
      postulaciones: {},
      filtros: {},
      fechaCor: null,
      roltransportadora: false,
      listasForms: {
        tipoServicios: [],
        slct_empresa: [],
        estados: [],
      },
      empresas: [],
    };
  },

  methods: {
    init(page = 1) {
      this.cargando = true;
      let me = this;
      // Se ajustan los filtros
      this.filtros.tipo_servicio = this.filtros.tipos_servicios
        ? this.filtros.tipos_servicios.numeracion
        : null;
      this.filtros.empresa_id = this.filtros.slct_empresa
        ? this.filtros.slct_empresa.id
        : null;
      this.filtros.estado = this.filtros.estados
        ? this.filtros.estados.numeracion
        : null;

      axios
        .get("/api/cs/postulacionesInternas?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.postulaciones = response.data;
          this.cargando = false;
        })
        .catch(function(error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    // Métodos para llenado de filtros
    getTipoServicios() {
      axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipoServicios = response.data;
      });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        this.cargando = true;
        var url = "api/admin/empresas/lista";
        axios
          .get(url, {
            params: {
              razon: search,
              linea_negocio_id: [4],
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            me.cargando = false;
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getEstadosSolicitudes() {
      axios.get("/api/lista/142").then((response) => {
        this.listasForms.estados_solicitudes = response.data;
      });
    },

    fechaCorte() {
      this.fechaAct = moment().format("YYYY-MM-DD");
      this.fechaCor = moment(this.fechaAct)
        .add(-30, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    },

    alertaModal() {
      this.$swal({
        title:
          "La solicitud realizada al ser fija no está abierta para postulantes. El único personal autorizado es el personal logístico de Frontera!",
        text: "Se excedió la fecha limite",
        icon: "error",
        confirmButtonText: "Aceptar!",
      });
    },

    // Buscar rol de empresa transportadora carga seca
    roles() {
      this.roltransportadora = false;
      let usuario = this.$store.state.user;
      for (let j = 0; j < usuario.roles.length; j++) {
        if (usuario.roles[j].id == 1) {
          this.roltransportadora = true;
        }
      }
    },

    // Métodos de acciónes del módulo
    asignarPostulaciones(data, accion) {
      return this.$router.push({
        name: "/Cs/PostulacionesInternosForm",
        params: {
          det_solicitud: data,
          accion: accion,
        },
      });
    },
  },
  mounted() {
    this.init();
    this.getEstadosSolicitudes();
    this.getTipoServicios();
    this.fechaCorte();
    this.roles();
  },
};
</script>
